import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

import { Divider, Menu, MenuItem } from '@mui/material';

import { ROUTE_ROLES } from '@/constants/roles';
import useDashboard from '@/hooks/useDashboard';
import { SET_LOGOUT } from '@/redux/mutations';
import GearIcon from '@assets/icons/GearSix.svg';
import NotificationsIcon from '@assets/icons/Notifications.svg';
import ProfileIcon from '@assets/icons/Profile.svg';
import QuestionIcon from '@assets/icons/Question.svg';
import useAuthService from '@services/auth';
import useNotificationService from '@services/notification';

import { redirectUser } from '../../../../utils/redirect';
import ContentFormModal from '../../modals/ContentForm';
import IconButton from '../IconButton';
import { Container, NotificationBadge, NotificationsContent } from './styles';

const NavbarIcons = ({}) => {
    const navigate = useNavigate();
    const { logout, setCurrentUserRole: setReduxCurrentUser } = useAuthService();
    const dispatch = useDispatch();
    const { openSettingsModal } = useDashboard();
    const { getAllNotificationsUser } = useNotificationService();
    const user = useSelector((state) => state.auth.user);
    const notificationsEmpty = useSelector((state) => state.notification.empty);

    const [menuAnchor, setMenuAnchor] = useState(null);
    const [showSettingsMenu, setShowSettingsMenu] = useState(false);
    const [showChangeRoleMenu, setShowChangeRoleMenu] = useState(false);
    const [menuChangeRole, setMenuChangeRole] = useState(null);
    const [menuHelpAnchor, setMenuHelpAnchor] = useState(null);
    const [showHelpMenu, setShowHelpMenu] = useState(false);
    const [currentUserRole, setCurrentUserRole] = useState(Number(sessionStorage.getItem('currentUserRole')));
    const consultantId = sessionStorage.getItem('consultant_id') ?? null;

    const [showContentFormModal, setShowContentFormModal] = useState(false);
    const handleUserRoleChange = (role) => {
        if (role !== currentUserRole) {
            setCurrentUserRole(role);
            sessionStorage.setItem('currentUserRole', Number(role));
            setReduxCurrentUser(Number(role));
            navigate('/');
        }
    };
    const [existNotification, setExistNotification] = useState(false);

    const handleOpenSettings = (evt) => {
        setMenuAnchor(evt.currentTarget);
        setShowSettingsMenu(true);
    };

    const handleCloseSettings = () => {
        setMenuAnchor(null);
        setShowSettingsMenu(false);
    };

    const handleSettingsModal = () => {
        handleCloseSettings();
        openSettingsModal();
    };

    const handleLogout = () => {
        sessionStorage.removeItem('consultant_id');
        sessionStorage.removeItem('consultant');
        sessionStorage.removeItem('currentUserRole');
        dispatch({ type: 'SET_VIEW_AS_CONSULTANT', payload: false });
        logout();

        dispatch({
            type: SET_LOGOUT
        });

        handleCloseSettings();
        navigate('/');
    };

    const handleCloseHelp = () => {
        setMenuHelpAnchor(null);
        setShowHelpMenu(false);
    };

    const handleOpenChangeRole = (evt) => {
        setMenuChangeRole(evt.currentTarget);
        setShowChangeRoleMenu(true);
    };

    const handleCloseChangeRole = () => {
        setMenuChangeRole(null);
        setShowChangeRoleMenu(false);
    };

    const handleContentSupportModal = () => {
        handleCloseHelp();
        setShowContentFormModal(!showContentFormModal);
    };

    function getAllNotifications() {
        getAllNotificationsUser()
            .then((resp) => {
                dispatch({ type: 'SET_NOTIFICATIONS', payload: resp.data });
                setExistNotification(resp.data.length > 0 ? true : false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // function getAllNotificationsEveryMinute() {
    //     setInterval(() => {
    //         if (sessionStorage.getItem('currentUserRole')) {
    //             getAllNotificationsUser().then((resp) => {
    //                 dispatch({ type: 'SET_NOTIFICATIONS', payload: resp.data });
    //                 setExistNotification(resp.data.length > 0 ? true : false);
    //             });
    //         }
    //     }, 60000);
    // }

    useEffect(() => {
        getAllNotifications();
        getAllNotificationsUser()
            .then((resp) => {
                dispatch({ type: 'SET_NOTIFICATIONS', payload: resp.data });
                setExistNotification(resp.data.length > 0 ? true : false);
            })
            .catch((error) => {
                console.log(error);
            });
        // Get all notifications each six minutes
        // const notificationsInterval = setInterval(() => {
        //     if (sessionStorage.getItem('currentUserRole')) {
        //         getAllNotificationsUser()
        //             .then((resp) => {
        //                 dispatch({ type: 'SET_NOTIFICATIONS', payload: resp.data });
        //                 setExistNotification(resp.data.length > 0 ? true : false);
        //             })
        //             .catch((error) => {
        //                 console.log(error);
        //             });
        //     }
        // }, 6000);

        // // getAllNotificationsEveryMinute();

        // return () => clearInterval(notificationsInterval);
    }, []);

    useEffect(() => {
        getAllNotifications();
    }, [notificationsEmpty]);

    return (
        <>
            <Container data-tour="1">
                {/* <NotificationsContent>
                    <IconButton variant={'transparent'} icon={NotificationsIcon} />
                    <NotificationBadge />
                </NotificationsContent> */}
                <NotificationsContent>
                    <IconButton
                        variant={'transparent'}
                        icon={NotificationsIcon}
                        onClick={() => navigate(redirectUser(currentUserRole, 'notificacoes'))}
                        disabled={Boolean(consultantId)}
                    />
                    {existNotification && <NotificationBadge />}
                </NotificationsContent>
                <IconButton
                    className="track-need-help"
                    variant={'transparent'}
                    icon={QuestionIcon}
                    onClick={() => window.open('https://wa.me/5519910034306', '_blank')}
                    disabled={Boolean(consultantId)}
                />

                {user?.roles?.length > 1 ? (
                    <IconButton
                        variant={'transparent'}
                        icon={ProfileIcon}
                        onClick={handleOpenChangeRole}
                        disabled={Boolean(consultantId)}
                    />
                ) : null}

                <IconButton variant={'transparent'} icon={GearIcon} onClick={handleOpenSettings} disabled={Boolean(consultantId)} />
            </Container>
            <Menu
                id="basic-menu"
                anchorEl={menuAnchor}
                open={showSettingsMenu}
                onClose={handleCloseSettings}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                <MenuItem onClick={handleSettingsModal}>Configurações do Perfil</MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>Sair</MenuItem>
            </Menu>

            <Menu
                id="basic-menu"
                anchorEl={menuChangeRole}
                open={showChangeRoleMenu}
                onClose={handleCloseChangeRole}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                {user.roles &&
                    user.roles.map((role, index) => (
                        <>
                            <MenuItem onClick={() => handleUserRoleChange(role)} style={{ textTransform: 'capitalize' }}>
                                {ROUTE_ROLES[role]}
                            </MenuItem>
                            {index + 1 < user.roles.length && <Divider />}
                        </>
                    ))}
                {/* <MenuItem onClick={() => alert('trocar perfil')}>Administrador</MenuItem> */}
            </Menu>
            {showContentFormModal && <ContentFormModal open={showContentFormModal} handleClose={handleContentSupportModal} />}
        </>
    );
};
export default NavbarIcons;

NavbarIcons.propTypes = {
    withGraph: PropTypes.bool
};
