import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { Grid, MenuItem, TextField } from '@mui/material';

import LoadingComponent from '@/components/templates/LoadingComponent';
import useEnterpriseService from '@/services/enterprise';
import useManagerPanelService from '@/services/managerPanel';
import { getDinamicFormat } from '@/utils/date';
import { handleDownloadData } from '@/utils/helpers/downloads';
import noImage from '@assets/images/no_image.jpeg';

import CardConsultant from './components/CardConsultant';
import SectionPerformance from './components/SectionPerformance';
import { Div, DivConsultant, GridConsultant, NoData, NoDataContent, PaperTitle, Section } from './styles';

const Performances = ({ idEnterprise }) => {
    const [loading, setLoading] = useState(true);
    const [filterPer, setFilterPer] = useState('not_valid_option');
    const [selectedConsultant, setSelectedConsultant] = useState(null);
    const [consultants, setConsultants] = useState([]);
    const [pdfDate, setPdfDate] = useState(null);

    const enterpriseService = useEnterpriseService();
    const dispatch = useDispatch();
    const { clickedReportButton } = useSelector((state) => state.managerPanelReducer);

    const { getPerformancePDFReport } = useManagerPanelService();

    const handleData = async () => {
        setLoading(true);
        const { data } = await enterpriseService.getEnterpriseConsultats(idEnterprise);

        const consultantsOptions = data.map((consultant) => ({
            value: consultant.id,
            label: consultant.name,
            data: consultant
        }));

        setConsultants(consultantsOptions);
        setLoading(false);
    };

    const handleSelectOption = (option) => {
        const selectedConsultantData = consultants.find((consultant) => consultant.value === option);
        setSelectedConsultant(selectedConsultantData ? selectedConsultantData.data : null);
        setFilterPer(option);
    };

    const handleReport = async () => {
        try {
            dispatch({ type: 'MANAGER_PANEL_REPORT_IS_LOADING', payload: true });
            const { data } = await getPerformancePDFReport(selectedConsultant.id, pdfDate);

            if (data) {
                const dateString = getDinamicFormat(pdfDate, 'MMM[_]YYYY');
                handleDownloadData(data, `desempenho_consultor_${selectedConsultant.name}_${dateString}`);
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch({ type: 'MANAGER_PANEL_RESET' });
        }
    };

    useEffect(() => {
        if (clickedReportButton) {
            handleReport();
        }
    }, [clickedReportButton]);

    useEffect(() => {
        handleData();
    }, []);

    return (
        <>
            <Section>
                {!loading ? (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <PaperTitle>Desempenho do Consultor</PaperTitle>
                        </Grid>
                        {consultants.length ? (
                            <>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} alignItems={'center'}>
                                        <Grid item>
                                            <p>Selecione o Consultor</p>
                                        </Grid>

                                        <Grid item>
                                            <Div width={'300px'}>
                                                <TextField
                                                    fullWidth
                                                    size={'small'}
                                                    select
                                                    value={filterPer}
                                                    onChange={(evt) => handleSelectOption(evt.target.value)}
                                                >
                                                    <MenuItem value={'not_valid_option'}>Selecione</MenuItem>
                                                    {consultants.map((consultant) => (
                                                        <MenuItem key={consultant.value} value={consultant.value}>
                                                            {consultant.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Div>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {filterPer != 'not_valid_option' ? (
                                    <Grid item xs={12}>
                                        <GridConsultant container>
                                            <Grid item xs={12}>
                                                {selectedConsultant && <CardConsultant data={selectedConsultant} />}
                                            </Grid>

                                            <Grid container xs={12} spacing={3} marginTop={0.5}>
                                                <SectionPerformance selectedConsultant={selectedConsultant} changeDate={setPdfDate} />
                                            </Grid>
                                        </GridConsultant>
                                    </Grid>
                                ) : null}
                            </>
                        ) : (
                            <NoDataContent>
                                <NoData>Nenhum dado encontrado</NoData>
                            </NoDataContent>
                        )}
                    </Grid>
                ) : (
                    <LoadingComponent hideLogo />
                )}
            </Section>
        </>
    );
};

export default Performances;
