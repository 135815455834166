import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Typography } from '@mui/material';

import ButtonCallAction from '@/components/BI/buttonCallAction';
import useEnterpriseEmployeesService from '@/services/enterprise-employees';
import useReportService from '@/services/report';
import { getFormatBRL } from '@/utils/date';
import { handleDownloadData } from '@/utils/helpers/downloads';
import { ReactComponent as CaretDown } from '@assets/icons/CaretDown.svg';
import { ReactComponent as IconPdf } from '@assets/icons/IconPdf.svg';
import noImage from '@assets/images/no_image.jpeg';

import Button from '../../buttons/Button';
import {
    PropertiesList,
    PropertyAccordionHeader,
    PropertyContainer,
    PropertyInfo,
    PropertyReport,
    PropertyReportItem,
    PropertyReportsList
} from './styles';

export const EmployeePropertiesList = ({ collaboratorId }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [properties, setProperties] = useState([]);
    const [isLoadingPDF, setIsLoadingPDF] = useState(undefined);
    const { getConsultantProperties } = useEnterpriseEmployeesService();
    const { showReportPDFPerId } = useReportService();

    const handleProperties = async () => {
        try {
            const {
                data: { data }
            } = await getConsultantProperties(collaboratorId, true);
            console.log(data);
            if (data) {
                setProperties(data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleDownloadPdf = async (reportId) => {
        setIsLoadingPDF(reportId);
        try {
            const data = await showReportPDFPerId(reportId);
            handleDownloadData(data, 'relatorio_de_visita');
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoadingPDF(undefined);
        }
    };

    useEffect(() => {
        handleProperties();
    }, [collaboratorId]);

    return (
        <>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <PropertiesList>
                    {properties.length > 0 &&
                        properties.map((item, index) => (
                            <Accordion key={item.id} expanded={expanded === `panel-${index}`} onChange={handleChange(`panel-${index}`)}>
                                <PropertyAccordionHeader expandIcon={<CaretDown />} aria-controls="panel1bh-content" id="panel1bh-header">
                                    <PropertyInfo>
                                        <div className={'logo'}>
                                            <img src={item.logo ? item.logo : noImage} alt={item.name} />
                                        </div>
                                        <div className={'name'}>
                                            <span className={'propertyName'} style={{ fontWeight: 'bold' }}>
                                                {item.name}
                                            </span>
                                            <span className={'propertyName'}>{item?.owner?.name}</span>
                                            {item.address && (
                                                <span>
                                                    {item.address.city} - {item.address.district}
                                                </span>
                                            )}
                                        </div>
                                    </PropertyInfo>
                                    {item.reports && item.reports.length > 0 && (
                                        <span className="reports-count">{item.reports.length} relatórios</span>
                                    )}
                                </PropertyAccordionHeader>
                                <AccordionDetails sx={{ backgroundColor: '#f9f9fa', padding: 0 }}>
                                    <PropertyReportsList>
                                        {item.reports && item.reports.length > 0 ? (
                                            item.reports.map((report) => (
                                                <PropertyReport key={report.id}>
                                                    <PropertyReportItem>
                                                        <span className="visit-number">Visita {report.visit_number}</span>
                                                        <span>{report.report?.reportTitle}</span>
                                                    </PropertyReportItem>
                                                    <PropertyReportItem>
                                                        <span>Data: {getFormatBRL(report.report?.visitDate || report.updated_at)}</span>
                                                        <ButtonCallAction
                                                            sx={{ fontSize: 14, padding: '0', height: '32px !important' }}
                                                            Icon={IconPdf}
                                                            loading={isLoadingPDF === report.id}
                                                            onClick={() => handleDownloadPdf(report.id)}
                                                        >
                                                            Gerar PDF
                                                        </ButtonCallAction>
                                                    </PropertyReportItem>
                                                </PropertyReport>
                                            ))
                                        ) : (
                                            <PropertyReportsList>
                                                <PropertyReport>
                                                    <span>Nenhum relatório cadastrado</span>
                                                </PropertyReport>
                                            </PropertyReportsList>
                                        )}
                                    </PropertyReportsList>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                </PropertiesList>
            )}
        </>
    );
};

EmployeePropertiesList.propTypes = {
    collaboratorId: PropTypes.string.isRequired
};

// <PropertyContainer key={item.id}>
//     <PropertyInfo>
//         <div className={'logo'}>
//             <img src={item.logo ? item.logo : noImage} alt={item.name} />
//         </div>
//         <div className={'name'}>
//             <span className={'propertyName'}>{item.name}</span>
//             {item.address && (
//                 <span>
//                     {item.address.city} - {item.address.district}
//                 </span>
//             )}
//         </div>
//     </PropertyInfo>
// </PropertyContainer>
