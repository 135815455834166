import useApi from '../api';

const usePropertyService = () => {
    const { api } = useApi();
    return {
        getProperty: (id) => api.get(`property/${id}`).then((resp) => resp.data),
        getResource: () => api.get('property/get_resources').then((resp) => resp.data),

        getCountPropertyPerMonth: (id, data, filterPerActiveOrBlocked) =>
            api
                .get(`property/get_properties_month/${id}`, {
                    params: {
                        data,
                        filterPerActiveOrBlocked
                    }
                })
                .then((resp) => resp.data),

        getPropertyByMilk: (id, data) =>
            api
                .get(`property/get_properties_milk/${id}`, {
                    params: {
                        ...data
                    }
                })
                .then((resp) => resp.data),
        createProperty: (data) =>
            api
                .post('property', data, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then((resp) => resp.data),
        getPropertyPerId: (id) => api.get(`property/${id}`).then((resp) => resp.data),
        getProperties: (search, orderBy, limit, optionFilter, enterpriseId) =>
            api
                .get('property', {
                    params: {
                        search,
                        orderBy,
                        limit,
                        optionFilter,
                        enterpriseId
                    }
                })
                .then((resp) => resp.data),
        blockProperty: (propertyId) => api.post(`property/toggle_block/${propertyId}`).then((resp) => resp.data),
        update: (data, id) => {
            data.append('_method', 'put');
            return api
                .post(`property/${id}`, data, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then((resp) => resp.data);
        },
        updateCpf: (id, data) => {
            return api.patch(`property/${id}/update_owner_cpf`, data).then((resp) => resp.data);
        }
    };
};

export default usePropertyService;
