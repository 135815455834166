import { useEffect, useMemo, useState } from 'react';

import moment from 'moment';

import useCollaboratorService from '@/services/collaborator';
import useReportService from '@/services/report';

export const useReportsPerConsultant = ({ idEnterprise, yearsOptions, setConsultantYearFilter, setConsultantMonthFilter }) => {
    const reportService = useReportService();
    const [selectedConsultant, setSelectedConsultant] = useState(null);
    const [yearFilter, setYearFilter] = useState(yearsOptions[0]);
    const [monthFilter, setMonthFilter] = useState(moment().month() + 1);
    const [years, setYears] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isLoadingReportZipRequest, setIsLoadingReportZipRequest] = useState(false);
    const [requestZipModal, setRequestZipModal] = useState(false);
    const [data, setData] = useState([]);
    const perColumn = 15;
    const maxColumns = 4;

    const columnCount = useMemo(() => {
        let colCount = Math.ceil(data.length / perColumn);
        if (colCount > maxColumns) {
            colCount = maxColumns;
        }
        return colCount;
    }, [data]);

    const { getCollaboratorPerId } = useCollaboratorService();

    const handleOpenConsultant = async (consultantId) => {
        const consultor = await getCollaboratorPerId(consultantId);
        setSelectedConsultant(consultor);
    };

    const getData = async () => {
        setLoading(true);
        try {
            const { data: reportsData } = await reportService.getReportsPerConsultant(idEnterprise, yearFilter, monthFilter);

            setData(reportsData ?? []);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const requestZipReport = async () => {
        setRequestZipModal(false);
        setIsLoadingReportZipRequest(true);
        try {
            const { data } = await reportService.getReportsPerConsultantZip(idEnterprise, yearFilter, monthFilter);

            if (data.success) {
                setRequestZipModal(true);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoadingReportZipRequest(false);
        }
    };

    const changeMonthFilter = (month) => {
        setMonthFilter(month);
        setConsultantMonthFilter(month);
    };

    const changeYearFilter = (year) => {
        setYearFilter(year);
        setConsultantYearFilter(year);
    };

    useEffect(() => {
        if (yearFilter) {
            getData();
        }
    }, [yearFilter, monthFilter]);

    return {
        yearFilter,
        monthFilter,
        columnCount,
        maxColumns,
        perColumn,
        data,
        loading,
        isLoadingReportZipRequest,
        selectedConsultant,
        setSelectedConsultant,
        requestZipModal,
        setRequestZipModal,
        requestZipReport,
        handleOpenConsultant,
        changeMonthFilter,
        changeYearFilter
    };
};
