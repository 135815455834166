import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';

import { SET_SEARCH } from '@/redux/mutations';
import { ReactComponent as Plus } from '@assets/icons/Plus.svg';
import { ReactComponent as ResultLogo } from '@assets/icons/Rehsult_icon.svg';
import LogoEnterprise from '@assets/images/no_image.jpeg';
import NavbarIcons from '@components/molecules/buttons/NavbarIcons';

import { Container, UserAvatar, Title, Pipe } from './styles';

const DashboardNavbar = ({ rightSidebar }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    const currentUserRole = Number(Cookies.get('currentUserRole'));
    const consultantId = sessionStorage.getItem('consultant_id') ?? null;
    const consultant = JSON.parse(sessionStorage.getItem('consultant')) ?? {};

    const handleSearch = (payload) => {
        dispatch({
            type: SET_SEARCH,
            payload
        });
    };

    return (
        <Container rightSidebar={rightSidebar} id="navbar" data-tour="6">
            <div className={'logotipo'}>
                {[1, 4, 5].includes(currentUserRole) ? (
                    <>
                        {consultantId === null ? (
                            <>
                                <ResultLogo onClick={() => !consultantId && navigate(`/`)} cursor="pointer" />

                                <Pipe />

                                <Title>Painel Administrativo</Title>
                            </>
                        ) : (
                            <>
                                <ResultLogo onClick={() => !consultantId && navigate(`/`)} cursor="pointer" />
                                {/* <Logo onClick={() => navigate(`/`)} cursor="pointer" /> */}
                                {consultant.enterprise && (
                                    <>
                                        <Plus />
                                        <img src={consultant.enterprise?.logo || LogoEnterprise} alt="Logo da empresa" />
                                    </>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <ResultLogo onClick={() => !consultantId && navigate(`/`)} cursor="pointer" />
                        {/* <Logo onClick={() => navigate(`/`)} cursor="pointer" /> */}
                        {user.enterprise && (
                            <>
                                <Plus />
                                <img src={user.enterprise?.logo || LogoEnterprise} alt="Logo da empresa" />
                            </>
                        )}
                    </>
                )}
            </div>
            <div>
                <NavbarIcons />
                {!consultantId ? (
                    <UserAvatar
                        src={
                            user.image ||
                            `https://eu.ui-avatars.com/api/?background=015641&color=ffffff&bold=true&rounded=true&font-size=0.6&size=128&length=1&name=${
                                user.name || 'Rehagro'
                            }`
                        }
                        alt={user.name || 'Rehagro'}
                    />
                ) : (
                    <UserAvatar
                        src={
                            consultant.photo ||
                            `https://eu.ui-avatars.com/api/?background=015641&color=ffffff&bold=true&rounded=true&font-size=0.6&size=128&length=1&name=${
                                consultant.name || 'Rehagro'
                            }`
                        }
                        alt={consultant.name || 'Rehagro'}
                    />
                )}
            </div>
        </Container>
    );
};

export default DashboardNavbar;
