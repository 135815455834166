import React from 'react';

import EnterprisePage from '@/pages/Common/EnterpriseDetails/EnterprisePage';
import Events from '@/pages/Common/Events';
import ViewEvent from '@/pages/Common/Events/ViewEvent';
import Glossary from '@/pages/Common/Glossary';
import Watch from '@/pages/Common/Glossary/Watch';
import Notifications from '@/pages/Common/Notifications';
import DashboardLayout from '@components/templates/layouts/Outlet/DashboardLayout';
const Painel = React.lazy(() => import('pages/Admin/Painel'));
const EnterpriseDetails = React.lazy(() => import('pages/Common/EnterpriseDetails'));

const SectorQuestions = React.lazy(() => import('pages/Admin/CheckAgro/SectorQuestions'));
const NewQuestion = React.lazy(() => import('pages/Admin/CheckAgro/NewQuestion'));
const Error404 = React.lazy(() => import('pages/Errors/404'));

const RehagroRoutes = {
    path: '/admin',
    element: <DashboardLayout />,
    children: [
        {
            path: '/admin/painel/:mainTab?',
            element: <Painel />
        },
        {
            path: '/admin/empresa/:tabValue?/:id?/:tab?',
            element: <EnterpriseDetails />
        },
        {
            path: '/admin/check-agro/setor/:sector_id/questoes',
            element: <SectorQuestions />
        },
        {
            path: '/admin/check-agro/setor/:sector_id/questoes/nova-questao',
            element: <NewQuestion />
        },
        {
            path: `/admin/check-agro/setor/:sector_id/questoes/editar-questao/:questionId`,
            element: <NewQuestion />
        },
        {
            path: '/admin/glossario',
            element: <Glossary />
        },
        {
            path: '/admin/glossario/assistir/:id',
            element: <Watch />
        },
        {
            path: '*',
            element: <Error404 />
        },
        {
            path: '/admin/encontros-ao-vivo',
            element: <Events />
        },
        {
            path: '/admin/encontros-ao-vivo/:id',
            element: <ViewEvent />
        },
        {
            path: '/admin/notificacoes',
            element: <Notifications />
        }
    ]
};
export default RehagroRoutes;
