import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import SectionTitle from '@/components/atoms/fonts/SectionTitle';
import ButtonBack from '@/components/BI/buttonBack';
import ButtonCallAction from '@/components/BI/buttonCallAction';
import LoadingComponent from '@components/templates/LoadingComponent';
import Meta from '@components/templates/Seo/Meta';
import useSnackbar from '@hooks/useSnackbar';
import useCourseService from '@services/course';

import { Header, Container, Main, Card } from './styles';

const Glossary = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [courses, setCourses] = useState('');
    const user = useSelector((state) => state.auth);
    const { courseUserInitial, getAllCourses } = useCourseService();
    const currentUserRole = Number(sessionStorage.getItem('currentUserRole'));
    const consultantId = sessionStorage.getItem('consultant_id') ?? null;
    const navigate = useNavigate();
    const snackbar = useSnackbar();

    function redirectUser(id) {
        switch (currentUserRole) {
            case 1:
                navigate(`/admin/glossario/assistir/${id}`);
                break;
            case 2:
                navigate(`/consultor/glossario/assistir/${id}`);
                break;
            case 3:
                navigate(`/empresa/glossario/assistir/${id}`);
                break;
            case 4:
                navigate(`/coordenador/glossario/assistir/${id}`);
                break;
            case 5:
                navigate(`/supervisor/glossario/assistir/${id}`);
                break;

            default:
                navigate(`/glossario/assistir/${id}`);
                break;
        }
    }

    useEffect(() => {
        getAllCourses(consultantId ? 2 : currentUserRole, user.user.enterprise.id)
            .then((resp) => {
                setCourses(resp.data);
                setIsLoading(false);
            })
            .catch(() => {
                snackbar('Propriedade não encontrada', 'error');
                navigate('/consultor/propriedades');
            });
    }, []);

    if (isLoading) {
        return <LoadingComponent />;
    }
    return (
        <>
            <Meta title="Saber Mais" />

            <ButtonBack />
            <Container>
                <Header>
                    <SectionTitle tituloString={'Saber Mais'} />
                </Header>
                <Main>
                    {courses.map((value, key) => (
                        <Card key={key}>
                            <img
                                src={value.image}
                                alt="imagem-curso"
                                style={{ borderRadius: '10px', height: '65%', width: '100%', objectFit: 'contain' }}
                            />
                            <p
                                style={{
                                    color: '#373848',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    marginTop: '16px'
                                }}
                            >
                                {value.title}
                            </p>
                            {/* <p style={{ fontWeight: 600, fontSize: '12px', marginTop: '16px' }}>{value.speaker}</p> */}
                            {/* <div style={{ height: '48px' }}>
                                <p style={{ fontSize: '12px', marginTop: '16px' }}>
                                    {value.description.length > 152 ? `${value.description.substring(0, 152)}...` : value.description}
                                </p>
                            </div>
                            <p style={{ fontWeight: 600, fontSize: '12px', marginTop: '2rem', marginBottom: '20px' }}>
                                {value.time} horas no total
                            </p> */}
                            <ButtonCallAction
                                height="44px"
                                endIcon={<ChevronRightIcon />}
                                iconSize="24px"
                                buttonColor="#3A6647"
                                onClick={() => {
                                    if (value.course_user === null) {
                                        courseUserInitial(value.id);
                                    }
                                    redirectUser(value.id);
                                }}
                            >
                                Iniciar
                            </ButtonCallAction>
                        </Card>
                    ))}
                </Main>
            </Container>
        </>
    );
};

export default Glossary;
