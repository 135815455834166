import React from 'react';
import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';

import SectionTitle from '@/components/atoms/fonts/SectionTitle';

import PageHeader from './components/PageHeader';
import { PageContent } from './style';
import Accesses from './tabs/Accesses';
import { ActionPlans } from './tabs/ActionPlans';
import { CheckAgro } from './tabs/CheckAgro';
import Performances from './tabs/Performances';
import Properties from './tabs/Properties';
import Reports from './tabs/Reports';
import Users from './tabs/Users';
import Visits from './tabs/Visits';

const EnterprisePage = ({ idEnterprise }) => {
    const params = useParams();
    const tab = params.tab ?? 'accesses';
    const renderPageContent = () => {
        switch (tab) {
            case 'accesses':
                return <Accesses idEnterprise={idEnterprise} tab={tab} />;
            case 'users':
                return <Users idEnterprise={idEnterprise} tab={tab} />;
            case 'properties':
                return <Properties idEnterprise={idEnterprise} tab={tab} />;
            case 'visits':
                return <Visits idEnterprise={idEnterprise} tab={tab} />;
            case 'reports':
                return <Reports idEnterprise={idEnterprise} tab={tab} />;
            case 'action_plan':
                return <ActionPlans idEnterprise={idEnterprise} tab={tab} />;
            case 'performance':
                return <Performances idEnterprise={idEnterprise} tab={tab} />;
            case 'check_agro':
                return <CheckAgro idEnterprise={idEnterprise} tab={tab} />;
            default:
                return <h2>Página &apos;{params.tab}&apos; em desenvolvimento!</h2>;
        }
    };

    return (
        <>
            <SectionTitle tituloString={'Painel do Gestor'} />
            <Grid container alignItems={'center'} justifyContent={'space-between'} marginBottom={'2rem'}>
                <Grid item xs={12}>
                    <PageHeader idEnterprise={idEnterprise} />
                </Grid>
            </Grid>

            <PageContent>{renderPageContent()}</PageContent>
        </>
    );
};

export default EnterprisePage;
